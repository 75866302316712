import {
  Breadcrumbs,
  Button,
  config,
  getColor,
  Heading,
  useCallbackRef,
} from '@faxi/web-component-library';
import {
  DataState,
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validationRegexes,
  validators,
} from '@faxi/web-form';
import classNames from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CalendarField, Icon, InputField, TagsField } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import parse from 'html-react-parser';
import { validateEndDate } from '../../utils';
import { UserContext } from '../../../../store';
import UploadEmails from './UploadEmails/UploadEmails.component';

import EmailsModal from './EmailsModal/EmailsModal.component';
import apiSurvey from '../../../../modules/api/apiSurvey';
import SustainabilityContext from '../../providers/Sustainability/Sustainability.context';
import { snackBarSuccessMessage } from '../../../../utils';

import * as Styled from './CreateSurvey.styles';

const CreateSurvey = () => {
  const [emails, setEmails] = useState<string[]>([]);

  const [seeAllEmails, setSeeAllEmails] = useState(false);

  const { userPreferences } = useContext(UserContext);

  const { disabledDates, retrySurveys } = useContext(SustainabilityContext);

  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();

  const { surveyId, organisationId, surveyName } = useParams() as {
    surveyId: string;
    organisationId: string;
    surveyName: string;
  };

  const [uploadEmailsFromFile, setUploadEmailsFromFile] = useState(false);

  const { communityId } = useContext(UserContext);

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (formValues: DataState) => {
      try {
        if (surveyId) {
          const requestData = {
            organisation_id: communityId,
            emails: emails,
          };

          const { data } = await apiSurvey.sendSurvey(
            surveyId,
            requestData as any
          );
          if (data.status === 'Success') {
            snackBarSuccessMessage('Survey sent');
            navigate(
              `/community/${organisationId}/admin/sustainability/${surveyId}`
            );
            retrySurveys();
          }
        } else {
          const requestData = {
            name: formValues.name,
            organisation_id: communityId,
            start_date: dayjs(formValues.start_date).format(
              config.apiDateFormat
            ),
            end_date: dayjs(formValues.end_date).format(config.apiDateFormat),
            emails: JSON.stringify(emails),
          };

          const { status } = await apiSurvey.createSurvey(requestData as any);

          if (status === 'Success') {
            snackBarSuccessMessage('Survey created');
            navigate(`/community/${organisationId}/admin/sustainability`);
            retrySurveys();
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [communityId, emails, navigate, organisationId, surveyId, retrySurveys]
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required(
          t('validation-field_is_required', { fieldname: t('name') })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('name').toLowerCase(),
            number: '50',
          })
        ),
      ],
      start_date: [validators.general.required(t('required_start_date'))],
      end_date: [
        validators.general.required(t('required_end_date')),
        validateEndDate(
          t('survey-range_cant_choose_range'),
          disabledDates as Dayjs[]
        ),
      ],
    }),
    [t, disabledDates]
  );

  const formValues = useFormRefValues(form, 'end_date', 'start_date');

  const breadcrumbsLinks = useMemo(
    () => [
      {
        text: surveyName || '',
        href: `/community/${organisationId}/admin/sustainability/${surveyId}`,
      },
      {
        text: t('send_survey-title_sernd_survey_via_email'),
        href: '',
      },
    ],
    [organisationId, surveyId, surveyName, t]
  );

  const disabledSubmit = useMemo(
    () =>
      !(form?.isFormChanged() && form?.asyncFormValid && form?.syncFormValid),
    [form]
  );

  const tagValidations = useMemo(
    () => ({
      emails: [
        validators.general.regex(
          validationRegexes.workEmail,
          t('validation-field_valid_email', {
            fieldname: t('register_email_hint').toLowerCase(),
          })
        ),
      ],
    }),
    [t]
  );

  const handleChangeEmails = useCallback(
    (emailsValue: string[]) => {
      let hasValidationError = false;

      emailsValue.forEach((domain) => {
        tagValidations.emails.forEach((validation) => {
          if (validation(domain)) {
            hasValidationError = true;
            return;
          }
        });
      });
      if (!hasValidationError) {
        setEmails(emailsValue);
      }
    },
    [tagValidations.emails]
  );

  return (
    <Styled.CreateSurvey className={classNames('kinto-page', 'sustainability')}>
      {surveyId && (
        <Breadcrumbs
          tabIndex={0}
          className="create-survey__breadcrumbs"
          aria-label="breadcrumbs"
          crumbs={breadcrumbsLinks}
        />
      )}

      <div className="create-survey__header">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className={classNames('kinto-page__heading', {
            'kinto-page__heading__send-survey': surveyId,
          })}
        >
          {t(
            surveyId
              ? 'send_survey-title_sernd_survey_via_email'
              : 'notification-title_new_survey'
          )}
        </Heading>

        {!surveyId && (
          <Button
            variant="ghost"
            onClick={() => {
              navigate('..');
            }}
            icon={<Icon name="xmark" />}
            iconPosition="right"
          >
            {t('PendingNotification_close')}
          </Button>
        )}
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        {!surveyId && (
          <fieldset className="create-survey__survey-name">
            <legend data-hidden>{t('global-name')}</legend>
            <FormField
              required
              name="name"
              component={InputField}
              autoComplete="off"
              placeholder={t('global-name')}
              validate={validations.name}
            />
          </fieldset>
        )}

        {!surveyId && (
          <div className="create-survey__duration">
            <div className="create-survey__duration__header">
              <Icon name="clock-regular" color={getColor('--PRIMARY_1_1')} />
              <Heading level="2">{t('global-duration')}</Heading>
            </div>
            <fieldset className="create-survey__duration__fields">
              <FormField
                required
                name="start_date"
                renderAsPortal
                component={CalendarField}
                leftLimitDate={dayjs()}
                rightLimitDate={formValues?.end_date || dayjs().add(2, 'years')}
                disabledDates={disabledDates}
                className="kinto-survey-modal__start-date"
                placeholder={t('campaign-start_date')}
                dateFormat={userPreferences.dateFormat}
                validate={validations.start_date}
              />

              <FormField
                required
                name="end_date"
                renderAsPortal
                component={CalendarField}
                leftLimitDate={formValues?.start_date || dayjs()}
                rightLimitDate={dayjs().add(2, 'years')}
                disabledDates={disabledDates}
                className="kinto-survey-modal__end-date"
                placeholder={t('campaign-end_date')}
                dateFormat={userPreferences.dateFormat}
                validate={validations.end_date}
              />
            </fieldset>
          </div>
        )}

        <div className="create-survey__participants">
          <div className="create-survey__participants__header">
            <Icon name="users-regular" color={getColor('--PRIMARY_1_1')} />
            <Heading level="2">{t('global-participants')}</Heading>
          </div>
          <div className="create-survey__participants__content">
            <p className="create-survey__participants__content__explanation">
              <strong>
                {parse(
                  t('participants_section-app_users_info').replace(
                    /\\n/g,
                    '<br />'
                  )
                )}
              </strong>
              {t('participants_section-not_signed_up_users_info')}
            </p>
            {uploadEmailsFromFile ? (
              <UploadEmails
                onClose={() => {
                  setUploadEmailsFromFile(false);
                }}
                onSubmit={(emailsValue) => {
                  setEmails((old) => [...old, ...emailsValue]);
                }}
              />
            ) : (
              <TagsField
                name="participant_emails"
                placeholder={t('global-placeholder_add_email_address')}
                label={t('preapproved_emails')}
                validateTag={tagValidations.emails as any}
                value={emails}
                onChange={handleChangeEmails}
              />
            )}

            <div className="create-survey__participants__upload-csv-btn">
              {!uploadEmailsFromFile && (
                <Button
                  variant="outline"
                  onClick={() => setUploadEmailsFromFile(true)}
                >
                  {t('global-button_label_upload_email_list_csv')}
                </Button>
              )}
              {emails.length !== 0 && (
                <Button
                  variant="ghost"
                  iconPosition="right"
                  icon={<Icon name="chevron-right" />}
                  onClick={() => setSeeAllEmails(true)}
                >
                  {t('preapproved_settings-button_see_all_emails')}
                </Button>
              )}
            </div>
          </div>
        </div>

        {seeAllEmails && (
          <EmailsModal
            emailList={emails}
            onClose={() => {
              setSeeAllEmails(false);
            }}
            onRemoveEmail={(email) => {
              setEmails((old) => old.filter((e) => e !== email));
            }}
          />
        )}

        {surveyId ? (
          <div className="create-survey__send-actions">
            <Button type="submit" disabled={emails.length === 0}>
              {t('send')}
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('cancel')}
            </Button>
          </div>
        ) : (
          <Button
            type="submit"
            className="create-survey__run-survey-btn"
            disabled={disabledSubmit}
          >
            {t('global-run_survey')}
          </Button>
        )}
      </Form>
    </Styled.CreateSurvey>
  );
};

export default CreateSurvey;
